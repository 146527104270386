<template>
  <div class="patentList contBox">
    <search-box>
      <template #top>
        <div class="search-top flex-between searchBox">
          <el-form
            :inline="true"
            :model="formData"
            ref="searchForm"
            class="demo-form-inline"
          >
            <el-form-item label="姓名：">
              <el-input
                v-model="searchData.patient_name"
                class="w-180"
                clearable @input="handleSearch"
                placeholder="请输入姓名"
              >
                <template #prefix>
                  <i class="fa fa-search"></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="病案号：">
              <el-input
                v-model="searchData.case_no"
                class="w-180"
                clearable @input="handleSearch"
                placeholder="请输入病案号"
              >
                <template #prefix>
                  <i class="fa fa-search"></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="时间：" v-if="screenWidth > 1740">
              <el-date-picker
                v-model="searchData.time"
                type="daterange"
                value-format="YYYY-MM-DD"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="searchBut">
            <!-- <el-button class="color-yellow" @click="resetForm" type="primary">
              <i class="fa fa-print"></i>批量打印
            </el-button> -->
            <el-button @click="handleSearch" class="color-main" type="primary">
              <i class="fa fa-file-text-o"></i>修改模板
            </el-button>
            <el-button class="color-blue" @click="handleSearch" type="primary">
              <i class="fa fa-search"></i>查询
            </el-button>
          </div>
        </div>
      </template>
      <template #cont>
        <div class="searchBox">
          <el-form
            :inline="true"
            :model="formData"
            ref="searchForm"
            class="demo-form-inline"
          >
            <el-form-item label="时间：" v-if="screenWidth <= 1740">
              <el-date-picker
                v-model="searchData.time"
                type="daterange"
                value-format="YYYY-MM-DD"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="班次：">
              <el-select v-model="searchData.nm_name" placeholder="请选择">
                <el-option label="上午" value="上午"></el-option>
                <el-option label="下午" value="下午"></el-option>
                <el-option label="晚上" value="晚上"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="院区：">
              <el-select
                v-model="searchData.hospital_area_id"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in hospitalList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="留治/转出：">
              <el-select
                v-model="searchData.dict_after_weight_way"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="住院：">
              <el-select
                v-model="searchData.dict_after_weight_way"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="searchData.custom">执行医嘱</el-checkbox>
              <el-checkbox v-model="searchData.custom">透析评估</el-checkbox>
              <el-checkbox v-model="searchData.custom">监测记录</el-checkbox>
              <el-checkbox v-model="searchData.custom">症状处理</el-checkbox>
              <el-checkbox v-model="searchData.custom">并发症</el-checkbox>
            </el-form-item> -->
          </el-form>
        </div>
      </template>
    </search-box>
    <div class="dialysisReportList flex-start">
      <div class="list-left">
        <div class="table">
          <el-table
            ref="singleTableRef"
            v-loading="tableLoading"
            :data="tableData"
            highlight-current-row
            size="small"
            class="table-ellipsis"
            border
            @current-change="handlePdf"
          >
            <!-- <el-table-column fixed type="selection" width="55" /> -->
            <el-table-column prop="case_no" label="病案号" width="100" />
            <el-table-column prop="name" label="姓名" width="100" />
            <el-table-column prop="date" label="透析日期" width="120" />
            <el-table-column prop="bed_no" label="床位号" />
          </el-table>
        </div>
        <div class="pagination-box">
          <el-pagination
            v-model:currentPage="pageData.currentPage"
            v-model:page-size="pageData.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :background="true"
            layout="prev, pager, next"
            :total="pageData.totalSum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div class="dialysis-pdf contBox">
        <div class="explain">
          <span class="name">模板说明</span>
          <span class="tip">包含主要的透析信息</span>
        </div>
        <div class="pdfBox" v-loading="pdfLoading">
          <iframe :src="pdfURL" style="height: 100%; width: 100%"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted, defineExpose, onBeforeUnmount } from 'vue'
import service from '@/utils/request'
import dayjs from 'dayjs'
import searchBox from '@/components/search/searchBox.vue'
import comm from '@/utils/comm'

const tableData = ref([])
const tableLoading = ref(false)
const searchData = reactive({
  time: [],
})
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const singleTableRef = ref(null)

const screenWidth = ref(document.body.clientWidth)
defineExpose({
  screenWidth,
})
onMounted(() => {
  initData()
  getHospitalList()
  window.addEventListener('resize', () => {
    return (() => {
      screenWidth.value = document.body.clientWidth
    })()
  })
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', () => {
    return (() => {
      screenWidth.value = document.body.clientWidth
    })()
  })
})

const initData = () => {
  const startTime = dayjs(new Date()).format('YYYY-MM-DD')
  const endTime = dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD')
  searchData.time = [startTime, endTime]
  getDataList()
}

const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val

  getDataList()
}

const handleSearch = () => {
  pageData.currentPage = 1

  getDataList()
}

const getDataList = async () => {
  searchData.current = pageData.currentPage
  searchData.size = pageData.pageSize
  if (searchData.time.length > 0) {
    searchData.start_time = searchData.time[0]
    searchData.end_time = searchData.time[1]
  }
  tableLoading.value = true
  let res = await service.post('/api/dialysisreport/report', searchData)
  tableLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.records
    pageData.totalSum = res.data.total
    if (tableData.value.length > 0) {
      singleTableRef.value.setCurrentRow(tableData.value[0])
    }
  }
}

const hospitalList = ref([])
const getHospitalList = async () => {
  let res = await service.post('/api/hospital/list', {
    parent_id: 0,
  })
  if (res.code === 0) {
    hospitalList.value = res.data
    // if (res.data.length > 0) {
    //   searchData.hospital_area_id = res.data[0].id
    // }
  }
}

const pdfURL = ref('')
const pdfLoading = ref(false)
const handlePdf = async (item) => {
  pdfLoading.value = true
  let res = await service.post('/api/dialysisreport/get_report_pdf', {
    record_id: item.record_id,
  })
  pdfLoading.value = false
  if (res.code === 0) {
    let port = location.protocol
    let ip = comm.cesuUrl
    let requestUrl = `${port}//${ip}`
    pdfURL.value = requestUrl + res.data.pdf
  }
}
</script>

<style scoped lang="scss">
.table {
  margin-top: 0;
}
.patentList {
  height: 100%;
}
.searchBox {
  .el-form {
    margin-top: 0;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .w-180 {
    width: 180px;
  }
  &:deep(.el-date-editor) {
    width: 300px;
  }
  .el-select {
    width: 144px;
  }
}
.search-top .demo-form-inline {
  margin-top: 0;
  .el-form-item {
    margin-bottom: 0;
  }
}
.dialysisReportList {
  margin-top: 8px;
  height: calc(100% - 55px);
  .list-left {
    width: 400px;
    margin-right: 8px;
  }
  .dialysis-pdf {
    width: calc(100% - 408px);
    padding-top: 0;
    padding-left: 0;
  }
}
.dialysis-pdf {
  .explain {
    height: 40px;
    line-height: 40px;
    color: #3166ae;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 0 16px;
    .name {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}
.pdfBox {
  height: calc(100% - 66px);
}
</style>
